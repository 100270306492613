<template>
  <sign-page
    title-text="补贴工资总览"
    :request="request"
    table-size="large"
    :search-parms="searchParms"
    :initSearchData="{year: $datetime.format(new Date(), 'Y')}"
    @clickTitleMenu="clickTitleMenu"
    :titleMenus="[{key: 'batch', label: '批量调补贴'}]"
    :column-list="columnList"
    :tableActions="[]"
    :dealSearchParm="dealSearchParm"
    :table-actions-fixed="true"
    :tableActionsWidth="100"
    :need-data-file="true">
    <wage-form
      @dataUpdate="dataUpdate"
      :columnList="columnList"
      valueKey="subsidy"
      type="subsidy"
      :workerList="workers"
      ref="wafeForm"
      title="批量调补贴"></wage-form>
  </sign-page>
</template>

<script>
import {
  workerWageRequest as request,
  performanceWorkerRequest
} from '../../api'
import WageForm from './cmp/form'
import { number } from 'echarts'

export default {
  components: {
    WageForm
  },
  created () {
    this.loadWorker()
  },
  methods: {
    async loadData (parm) {
      let data = await request.get(parm)
      let dataMap = {}
      data.forEach(v => {
        if (!dataMap[v.performanceWorkerId]) {
          dataMap[v.performanceWorkerId] = {
            workerName: v.workerName
          }
        }
        dataMap[v.performanceWorkerId]['subsidy_' + Number(v.month.slice(5))] = v.subsidy
      })
      return Object.keys(dataMap).map(key => dataMap[key])
    },
    dataUpdate () {
      this.pageVm.loadData()
    },
    dealSearchParm (parm) {
      if (parm) {
        parm.year = parm.year && parm.year instanceof Date ? this.$datetime.format(parm.year, 'Y') : parm.year
      }
      return parm
    },
    clickTitleMenu (key, pageVm) {
      this.pageVm = pageVm
      if (key === 'batch') {
        this.$refs.wafeForm.open()
      }
    },
    async loadWorker () {
      let datas = await performanceWorkerRequest.get({})
      this.workers = datas.map(v => {
        return {
          key: v.id,
          label: v.name,
          data: v
        }
      })
    }
  },
  computed: {
    searchParms () {
      return [{
        type: 'yearPicker',
        placeholder: '年份',
        key: 'year',
        default: new Date()
      },
      {
        type: 'input',
        placeholder: '姓名',
        key: 'likeWorkerName'
      }]
    },
    columnList: {
      get () {
        let data = [{
          title: '',
          fixed: 'left',
          field: 'workerName'
        }]
        let i = 1
        while (i < 13) {
          data.push({
            title: i + '月',
            field: 'subsidy_' + i,
            sort: true,
            type: number
          })
          i += 1
        }
        return data
      }
    }
  },
  data () {
    return {
      workers: [],
      request: {
        get: this.loadData
      }
    }
  }
}
</script>
